<template>
    <div class="tnf-allyship__page tnf-allyship__error">
        <Header />
        <main>
            <slot />
        </main>
    </div>
</template>

<style>
    @import url('https://fonts.cdnfonts.com/css/helvetica-neue-55');
</style>